﻿/* global tumarket */

import * as $ from "jquery";

import "bootstrap/js/dist/popover";
import tumGlobal from "./global.js";
import { ajaxGet, ajaxSend } from "./global/fetch";
import { getUrlParameter } from "./global/url";
import { getCookie, setCookie } from "./global/cookie";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "./global/templates";
import { addEventDelegate, empty, hasClass, setHtml, setProperty, toggleClass } from "./global/dom";

if (!Object.entries)
    Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
};

var locationDetect = {
	options: {
		urlReferrer: ''
	},
	invalidLocation: false,
	init: function (options) {
		Object.assign(this.options, options);
		var cookiesAreEmpty = !getCookie("geo_cityID") && !getCookie("geo_subDomain") && !getCookie("geo_isNear");
		var IsNeedYandexRequest = getCookie("geo_IsNeedYandexRequest")==""; //нужен ли яндекс
		var forceLocationPopover = false;
		var _location = getCookie('geo_location');

		this.invalidLocation = _location && JSON.parse(_location).invalid;
		if (getCookie("geo_forceLocationPopover") == "1") {
			forceLocationPopover = true;
			setCookie("geo_forceLocationPopover", "", -1);
		}

		addEventDelegate(document, 'click', '.tum-header-citiesNearby', element => {
			toggleClass(element, "show");
			if (hasClass(element, "show")) {
				setHtml(element, element.dataset.items);
			} else {
				setHtml(element, element.dataset.short);
			}
		});

		addEventDelegate(document, "click", ".tum-location-selectCity, .tum-location-selectCityAndPool", (element, e) => {
			e.preventDefault();
			setCookie("geo_resetLocation", "1", 1);
			if (IsNeedYandexRequest) {
				setCookie("geo_IsNeedYandexRequest", "1", 1); //один раз установили через яндекс и все, дальше только по урле
				location.href = element.getAttribute("href");//перезагрузку экрана делаем только при получении яндекса
			} else {
				let popover = document.querySelector('.locationDetectPopover').closest('.popover');
				toggleClass(popover, 'show', false);
				toggleClass(popover, 'hide', false);
			}
		});

		addEventDelegate(document, "click", ".restoreOldLocation", (element, e) => {
			e.preventDefault();
			setCookie("geo_resetLocation", "1", 1);
			location.href = element.getAttribute("href");
		});

		addEventDelegate(document, 'change', '.city-check input, .tum-head-ellipses input', element => {
			var isNear = element.checked ? "1" : "0";
			var parentCityID = element.dataset.parentcityid;
			var citySubDomain = element.dataset.citysubdomain;
			this.changeIsNear(isNear, parentCityID, citySubDomain);
		});

		loadPrecompiledTemplates(['location-popup']).then(() => {
			if (cookiesAreEmpty) {
				if (IsNeedYandexRequest) {
					this.getLocation();
				}
				else { 
					this.getGeoData("Благовещенск", null, null, true);
				}
			}
			else if (forceLocationPopover) {
				ajaxSend({ url: '/selectregion/getlocationinfo', data: {} }).then(locationData => {
					if (!locationData) return;
					var name = locationData.name;
					if (locationData.cityID > 0 || locationData.citySubDomain || locationData.parentCityID) {
						this.getGeoData(name);
					}
					else {
						this.getGeoData("", name);
					}
				});
			}
			else {
				this.checkUrl();
			}
		});
	},
	changeIsNear: function (isNear, parentCityID, citySubDomain) {
		var cookieSubDomain = getCookie("geo_subDomain");

		var url = new URL(location.href);
		var isDev = url.hostname.indexOf("dev.") == 0 || url.hostname == "localhost";

		var params = {};
		url.searchParams.forEach(function (value, key) {
			if (key == "cityID" || key == "subDomain" || key == "isNear") return;
			params[key] = value;
		});


		if (cookieSubDomain) {
			if (!isDev) {
				if (url.hostname.split(".").length > 2) url.hostname = url.hostname.substring(url.hostname.indexOf(".") + 1);
				url.hostname = cookieSubDomain + "." + url.hostname;
			}
			else {
				params.subDomain = cookieSubDomain;
			}
		}

		var includePool = isNear == "1";

		if (includePool) {
			if (!citySubDomain && parentCityID != "3") {
				params.cityID = parentCityID;
			}
		}
		else {
			params.cityID = parentCityID;
			if (!citySubDomain && parentCityID != "3") params.isNear = "0";
		}

		setCookie("geo_resetLocation", "1", 1);
		url.search = Object.entries(params).map(e => e[0] + "=" + e[1]).join("&");

		location.href = url.toString();
	},
	getLocation: function () {
		let self = this;

		if (location.href.toString().indexOf("/selectregion") > -1) {
			//если сами указываем город, значит яндекс не нужен
			setCookie("geo_IsNeedYandexRequest", "1", 1);
			return;
		}

		function yandexLocationSuccess(e){
			console.log(e);
			var model = e.response.GeoObjectCollection.featureMember.first()?.GeoObject.metaDataProperty;
			if (!model) {
				browserLocationFail();
				return;
			}
			var addressComponents = model.GeocoderMetaData.Address.Components;
			var localityItem = addressComponents.filter(e => e.kind == "locality");
			var locality = localityItem.length ? localityItem[0].name : "";
			var provinces = addressComponents.filter(e => e.kind == "province").map(e => e.name);

			var province1 = provinces.length > 0 ? provinces[0] : "";
			var province2 = provinces.length > 1 ? provinces[1] : "";

			///для отладки, потом нужно убрать
			console.log("locality", locality);
			console.log("province1", province1);
			console.log("province2", province2);

			if (locality && province1) {
				self.getGeoData(locality, province1, province2);
			} else {
				browserLocationFail();
			}
		}

		function browserLocationSuccess(e){
			let params = $.param({ 
				geocode: `${e.coords.longitude},${e.coords.latitude}`, 
				lang: "ru_RU", 
				apikey: "58d3ef21-0b56-4982-a66d-4f11b1f52d90", 
				format: "json" 
			});

			ajaxGet({ url: "https://geocode-maps.yandex.ru/1.x/?" + params })
			.then(e => {
				if (e.statusCode == 403) {
					return Promise.reject("yandex api access denied");
				}
				
				return yandexLocationSuccess(e);
			})
			.catch(() => browserLocationFail());
		}

		function browserLocationFail(){
			self.getGeoData("Благовещенск", null, null, true);
		}

		if (!('geolocation' in navigator)){
			browserLocationFail();
			return;
		}

		navigator.geolocation.getCurrentPosition(browserLocationSuccess, browserLocationFail);
	},
	getGeoData: function (locality, province1, province2, permissionDenied) {
		ajaxSend({ url: "/selectregion/getgeodata", data: { locality: locality, province1: province1, province2: province2 } }).then(data => {
			if (data.result) {
				this.showLocationPopover(data, permissionDenied);
			}
		});
	},
	getCurrentUrlWithoutRegion: function () {
		var url = new URL(location.href);

		var params = {};
		url.searchParams.forEach(function (value, key) {
			if (key == "cityID" || key == "subDomain" || key == "isNear") return;
			params[key] = value;
		});

		if (url.hostname.split(".").length > 2 && url.hostname.indexOf("dev.") != 0) url.hostname = url.hostname.substring(url.hostname.indexOf(".") + 1);

		if (params) { url.search = Object.entries(params).map(e => e[0] + "=" + e[1]).join("&"); }

		return url.toString();
	},
	showLocationPopover: function (geoModel, permissionDenied) {
		if (tumarket.disablePopups) return;

		if (!geoModel.citiesByLetter.length && !geoModel.regions.length) {
			this.getGeoData("Благовещенск");
			return;
		}

		var elem = tumGlobal.isMob() ? ".location-popover-mob" : ".location-popover";

		var pageTypes = ['product', 'firm'];

		if (location.href.indexOf(pageTypes[0]) != -1) {
			if (this.options.urlReferrer == '') {
				return;
			}
		} else if (location.href.indexOf(pageTypes[1]) != -1) {
			if (this.options.urlReferrer == '') {
				return;
			}
		}

		$(elem).popover({
			container: "body",
			html: true,
			placement: "bottom",
			trigger: "manual",
			content: this.renderLocationPopover(geoModel, permissionDenied),
			sanitize: false
		}).on("shown.bs.popover", function () {
			let popover = document.querySelector(".locationDetectPopover").closest(".popover");
			toggleClass(popover, 'locationDetectPopoverCont', true);
		});

		$(elem).popover("show");

		setTimeout(() => { 
			$(elem).popover("hide");
			ajaxSend({ url: '/selectregion/getlocationinfo' }).then(geolocation => {
				if (!geolocation) return;
				setCookie("geo_cityID", geolocation.cityID, 30);
				setCookie("geo_isNear", geolocation.isNear ? 1 : 0, 30);
				setCookie("geo_subDomain", geolocation.subDomain, 30);
				setCookie("geo_IsNeedYandexRequest", 1, 30);

				if (this.invalidLocation) {
					let correctUrl = this.getCorrectUrl(); 
					if (location.href != correctUrl) {
						location.href = correctUrl;
					}
				}
			});
		}, 5000);
	},
	renderLocationPopover: function (geoModel) {
		var isCity = false;
		var cities = [];
		var mainCities = [];

		if (geoModel.citiesByLetter.length) {
			isCity = true;
			cities = [].concat.apply([], geoModel.citiesByLetter.map(e => e.cities));
			mainCities = cities.filter(e => e.id == e.parentCityID);
		}

		let city = null;
		let cityRegion = null;
		let region = null;

		if (isCity) {
			city = cities[0];
			cityRegion = geoModel.regions.filter(e => e.id == city.regionInCouID);
			if (cityRegion.length) cityRegion = cityRegion[0];
			if (city.pool.length) city = city.pool[0];
			city.url = this.getCityUrl(city, mainCities, geoModel.regions, true);
		}
		else {
			region = geoModel.regions[0];
			region.url = this.getRegionUrl(region);
		}

		this.updateHeader(isCity ? city.name : region.name);

		let oldLocation = getCookie('old_location');
		if (oldLocation) oldLocation = JSON.parse(oldLocation);

		return getPrecompiledTemplate('location-popup')({
			city, cityRegion, region, isCity, isNear: !!getCookie('geo_isNear'), 
			oldLocation: (isCity ? city.name : region.name) == oldLocation?.name ? null : oldLocation?.name,
			oldSubdomain: oldLocation?.citySubDomain || oldLocation.subDomain,
			oldCityID: oldLocation?.cityID
		});
	},
	updateHeader: function (cityName) {
		setHtml(".tum-header-cityName", cityName);
		empty(".tum-header-citiesNearby");
		setProperty(".city-check input, .tum-head-ellipses input", "checked", true);
	},
	getCityUrl: function (city, cities, regions, includePool) {
		var url = new URL(location.href);
		var isDev = url.hostname.indexOf("dev.") == 0 || url.hostname == "localhost";
		var mainCity = cities.filter(e => e.id == city.id || e.id == city.parentCityID);
		if (mainCity.length) mainCity = mainCity[0];

		var isMainCityWithoutPool = !city.pool.length && city.id == city.parentCityID;

		var params = {};
		url.searchParams.forEach(function (value, key) {
			if (key == "cityID" || key == "subDomain" || key == "isNear") return;
			params[key] = value;
		});

		if (mainCity.domainName) {
			if (!isDev) {
				if (url.hostname.split(".").length > 2) url.hostname = url.hostname.substring(url.hostname.indexOf(".") + 1);
				url.hostname = mainCity.domainName + "." + url.hostname;
			}
			else {
				params.subDomain = mainCity.domainName;
			}

			if (!includePool) {
				params.cityID = city.сityID;
			}
		}
		else {
			var region = regions.filter(e => e.id == city.regionInCouID);
			if (region.length) region = region[0];
			if (!isDev) {
				if (url.hostname.split(".").length > 2) url.hostname = url.hostname.substring(url.hostname.indexOf(".") + 1);
				if (city.id != 3 && city.parentCityID != 3) url.hostname = region.domainName + "." + url.hostname;
			}
			else {
				if (city.id != 3 && city.parentCityID != 3) params.subDomain = region.domainName;
			}

			if (includePool) {
				if (city.parentCityID != 3) params.cityID = city.parentCityID;
			}
			else {
				params.cityID = city.id;
				if (city.id != 3 && city.parentCityID != 3 && !isMainCityWithoutPool) params.isNear = "0";
			}
		}

		if (params) { url.search = Object.entries(params).map(e => e[0] + "=" + e[1]).join("&"); }

		return url.toString();
	},
	getRegionUrl: function (region) {
		var url = new URL(location.href);
		var isDev = url.hostname.indexOf("dev.") == 0 || url.hostname == "localhost";

		var params = {};
		url.searchParams.forEach(function (value, key) {
			if (key == "cityID" || key == "subDomain" || key == "isNear") return;
			params[key] = value;
		});

		if (!isDev) {
			if (url.hostname.split(".").length > 2) url.hostname = url.hostname.substring(url.hostname.indexOf(".") + 1);
			url.hostname = region.domainName + "." + url.hostname;
		}
		else {
			params.subDomain = region.domainName;
		}

		if (params) { url.search = Object.entries(params).map(e => e[0] + "=" + e[1]).join("&"); }

		return url.toString();
	},
	checkUrl: function () {
		var urls = ["/search-", "/category", "/catalog"];

		if (urls.filter(e => location.href.toString().indexOf(e) > -1).length == 0) return;

		var cookieCityID = getCookie("geo_cityID");
		var cookieSubDomain = getCookie("geo_subDomain");
		var cookieIsNear = getCookie("geo_isNear");

		var currentUrl = new URL(location.href);
		var hasSubDomain = currentUrl.hostname.split('.').length > 2 || getUrlParameter("subDomain");

		var cityIDCheck = ((!cookieCityID || cookieCityID == "3") && !getUrlParameter("cityID")) || (cookieCityID && getUrlParameter("cityID") == cookieCityID);
		var subDomainCheck = (!cookieSubDomain && !hasSubDomain) || (cookieSubDomain && (currentUrl.hostname.split(".")[0] == cookieSubDomain || getUrlParameter("subDomain") == cookieSubDomain));
		var isNearCheck = (!cookieIsNear && !getUrlParameter("isNear")) || (cookieIsNear && getUrlParameter("isNear") == cookieIsNear);

		if (!(cityIDCheck && subDomainCheck && isNearCheck)) {
			let correctUrl = this.getCorrectUrl();
			if (location.href != correctUrl) history.replaceState({}, null, correctUrl);
		}
	},
	getCorrectUrl: function(){
		var citySubDomain = document.querySelector(".city-check input")?.dataset.citysubdomain;

		var cookieCityID = getCookie("geo_cityID");
		var cookieSubDomain = getCookie("geo_subDomain");
		var cookieIsNear = getCookie("geo_isNear");

		var url = new URL(location.href);
		var isDev = url.hostname.indexOf("dev.") == 0 || url.hostname == "localhost";

		var params = {};
		url.searchParams.forEach(function (value, key) {
			if (key == "cityID" || key == "subDomain" || key == "isNear") return;
			params[key] = value;
		});

		if (cookieSubDomain) {
			if (!isDev) {
				// hostname бесполезно исправлять, т.к. браузер не даст

				//if (url.hostname.split(".").length > 2) url.hostname = url.hostname.substring(url.hostname.indexOf(".") + 1);
				//url.hostname = cookieSubDomain + "." + url.hostname;
			}
			else {
				params.subDomain = cookieSubDomain;
			}
		}

		var includePool = cookieIsNear == "1";

		if (cookieCityID && cookieCityID != "0") {
			if (includePool) {
				if (!citySubDomain && cookieCityID != "3") {
					params.cityID = cookieCityID;
				}
			}
			else {
				params.cityID = cookieCityID;
				if (cookieCityID != "3" && !citySubDomain && cookieIsNear) params.isNear = cookieIsNear;
			}
		}

		url.search = Object.entries(params).map(e => e[0] + "=" + e[1]).join("&");

		return url.toString();
	}
};

export default locationDetect;